<template>
  <ul
    v-for="(filterGroup, index) in useCollection.state.filters"
    :key="index"
    class="collection__active__filters__items"
    v-show="useCollection.state.filtersOpen.includes(index)"
  >
    <template v-for="(option, index) in filterGroup.values" :key="index">
      <li
        v-if="useCollection.state.filtersApplied.includes(option.key)"
        @click="
          useCollection.filterToggle(
            option.key,
            option.key,
            props.collection_id,
            filterGroup.filterOptionId,
            $event
          )
        "
        :class="['collection__active__filters__item', showActive(option.key)]"
      >
        <p>
          {{ stripKey(option.key) }}
        </p>
      </li>
    </template>
  </ul>
  <div v-if="useCollection.state.filtersApplied.length" @click="useCollection.clearFilters(props.collection_id)" class="collection__active__filters__reset">
    <p class="accent">Reset filters</p>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useCollectionStore } from '../../stores/collection';

const props = defineProps({
  collection_id: {
    type: String,
    required: false,
  }
});

const useCollection = useCollectionStore();
const { state, getProducts } = storeToRefs(useCollection);

const stripKey = (tag) => {
  if (/:(.+)/.exec(tag) && /:(.+)/.exec(tag).length) {
    return /:(.+)/.exec(tag)[1].trim();
  }
  return tag;
};

/* eslint-disable consistent-return */
const showActive = (option) => {
  if (useCollection.state.filtersApplied.includes(option))
    return 'collection__active__filters__item--active';
  if (useCollection.state.sortByFilter.includes(option))
    return 'collection__active__filters__item--active';
};

</script>
