<template>
  <div class="collection">
    <div class="collection__header">
      <div :class="{ wrapper__lg: grid.laptop }">
        <div class="collection__filters">
          <div class="collection__filters__count">
            <div
              v-if="data.metafields && data.metafields.icon"
              v-html="data.metafields.icon"
            ></div>
            <p class="accent">
              {{ props.page_template === 'search' ? `Showing ${useCollection.state.filteredProductsCount} results` : `${useCollection.state.filteredProductsCount} products available` }} 
            </p>
          </div>
          <div @click="toggleFilterDrawer()" class="collection__filters__filter">
            <icon-filters />
            <p class="accent">Filter</p>
          </div>
          <div class="collection__filters__sort">
            <OnClickOutside @trigger="data.sortByOpen = false">
              <div
                @click="data.sortByOpen = !data.sortByOpen"
                :class="[
                  'collection__filters__sort__toggle',
                  {
                    'collection__filters__sort__toggle--open': data.sortByOpen,
                  },
                ]"
                ref="target"
              >
                <p class="accent">Sort</p>
                <icon-chevron />
              </div>
            </OnClickOutside>
            <transition-expand>
              <collection-sorting
                :sort_open="data.sortByOpen"
                :collection_id="props.id"
                :customer="props.customer"
              />
            </transition-expand>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper__lg">
      <div
        v-if="useCollection.state.filtersApplied.length"
        class="collection__active__filters"
      >
        <collection-active-filters :collection_id="props.id" />
      </div>
      <div class="collection__main">
        <template v-if="parseInt(props.products_count) > 0">
          <div class="collection__main__grid">
            <template v-if="useCollection.state.loading">
              <placeholder-item
                v-for="idx in data.productsPerPage"
                type="product-item"
                ratio="0.65"
                :key="idx"
              />
            </template>
            <template v-else>
              <product-item
                v-for="product in useCollection.state.products"
                :key="product.id"
                :product="product"
                :customer="customer"
                source="boost-api"
              ></product-item>
            </template>
          </div>
          <nav
            aria-label="Pagination"
            class="pagination__wrap"
            role="navigation"
            v-if="numberOfPages > 1"
          >
            <paginate
              :v-model="data.currentPage"
              :page-count="numberOfPages"
              :page-range="3"
              :margin-pages="2"
              :click-handler="handlePaginationChange"
              :prev-text="''"
              :next-text="''"
              :container-class="'pagination'"
              :page-class="'page'"
            >
            </paginate>
          </nav>
        </template>
        <template v-else>
          <p class="accent">
            Sorry, no matches. Please clear your filter and start over again.
          </p>
        </template>
      </div>
    </div>
    <OnClickOutside @trigger="closeFilterDrawer">
      <collection-filter-drawer
        :drawer_open="data.filterDrawer"
        :collection_id="props.id"
        :collection_handle="props.handle"
        :page="data.currentPage"
        :page_template="props.page_template"
        @toggle-drawer="toggleFilterDrawer"
      />
    </OnClickOutside>
  </div>
</template>

<script setup>
import { reactive, computed, onBeforeMount, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useCollectionStore } from '../../stores/collection';
import CollectionFilterDrawer from './CollectionFilterDrawer.vue';
import CollectionActiveFilters from './CollectionActiveFilters.vue';
import CollectionSorting from './CollectionSorting.vue';
import PlaceholderItem from './PlaceholderItem.vue';
import { OnClickOutside } from '@vueuse/components';
import TransitionExpand from './transitions/TransitionExpand.vue';
import { useGrid } from 'vue-screen';
import IconFilters from './icons/IconFilters.vue';
import IconChevron from './icons/IconChevron.vue';
import ProductItem from './ProductItem.vue';
import Paginate from 'vuejs-paginate-next';

const useCollection = useCollectionStore();
const { state, getProducts } = storeToRefs(useCollection);

const props = defineProps({
  customer: {
    type: Object,
    required: true,
  },
  id: {
    type: String,
    required: false,
  },
  handle: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  query: {
    type: String,
    required: false,
  },
  products_count: {
    type: String,
    required: true,
  },
  page_template: {
    type: String,
    required: true,
  },
});

const data = reactive({
  productsPerPage: 16,
  currentPage: 1,
  filterDrawer: false,
  metafields: null,
  sortByOpen: false,
});

const grid = useGrid({
  mobile: '416px',
  tablet: '640px',
  'tablet-lg': '1024px',
  laptop: '1152px',
  desktop: '1460px',
  ultrawide: '2000px',
});

const numberOfPages = computed(() => {
  return Math.ceil(
    useCollection.state.filteredProductsCount / data.productsPerPage
  );
});

const handlePaginationChange = (pageNum) => {
  data.currentPage = pageNum;
};

const toggleFilterDrawer = () => {
  data.filterDrawer = !data.filterDrawer;
  data.filterDrawer
    ? document.body.classList.add('hide__scroll')
    : document.body.classList.remove('hide__scroll');
};

const closeFilterDrawer = () => {
  data.filterDrawer = false;
  document.body.classList.remove('hide__scroll');
};

const fetchMetafields = () => {
  fetch(`/collections/${props.handle}?view=metafields`)
    .then((res) => res.json())
    .then((metafieldsPayload) => {
      data.metafields = metafieldsPayload;
    });
};

onBeforeMount(() => {
  if (props.page_template !== 'search') {
    useCollection.state.pageTemplate = 'collection';
    fetchMetafields();
  } else {
    useCollection.state.pageTemplate = 'search';
    useCollection.state.searchQuery = props.query;
  }
});
</script>
