import { defineStore } from 'pinia';
import { reactive, computed } from 'vue';
import axios from 'axios';

export const useCollectionStore = defineStore('collection', () => {
  const state = reactive({
    products: [],
    filters: [],
    filtersOpen: [],
    filtersApplied: [],
    filterTagsApplied: [],
    sortByFilter: '',
    filteredProductsCount: '',
    loading: false,
    sortByOpen: false,
    defaultSortBy: 'best-selling',
    pageTemplate: '',
    searchQuery: null,
  });

  const getProducts = computed(() => {
    return state.products;
  });

  const setProducts = (products, count) => {
    state.products = products;
    state.filteredProductsCount = count;
  };

  const setFilters = (filters) => {
    state.filters = filters.filter((group) => group.values.length > 1);
  };

  const filterToggle = (tag, value, collectionId, filterOptionId) => {
    state.loading = true;
    // Reset products
    state.products = [];
    // this.noMoreProducts = false;

    if (!state.filtersApplied.includes(tag)) {
      // Add filter
      if (tag.includes('sort')) {
        state.sortByFilter = value;
      } else {
        state.filtersApplied.push(tag);
        state.filterTagsApplied.push(`${filterOptionId}:${value}`);
      }
    } else {
      // Remove filter
      state.filterTagsApplied.splice(
        state.filterTagsApplied.indexOf(`${filterOptionId}:${value}`),
        1
      );
      state.filtersApplied.splice(state.filtersApplied.indexOf(tag), 1);
    }
    getFilteredCollection(1, collectionId);
    state.loading = false;
  };

  const clearFilters = (collectionId) => {
    state.filterTagsApplied = [];
    state.filtersApplied = [];
    state.products = [];
    getFilteredCollection(1, collectionId);
  };

  const getFilteredCollection = async (page, collectionId) => {
    state.loading = true;

    // BCSF Endpoint
    const url = new URL('https://services.mybcapps.com/bc-sf-filter/');

    // Request options
    const params = {
      shop: 'amalgamated-hardware-merchants.myshopify.com',
      page,
      limit: 16,
      product_available: false,
      build_filter_tree: true,
      collection_scope: collectionId,
    };

    // Collection vs Search
    if (state.pageTemplate === 'search' && state.searchQuery) {
      url.pathname += 'search';
      params.q = state.searchQuery;
    } else {
      url.pathname += 'filter';
    }

    // Set sort order
    if (state.sortByFilter) {
      switch (state.sortByFilter) {
        case 'best-selling':
          params.sort = 'best-selling';
          break;
        case 'title-ascending':
          params.sort = 'title-ascending';
          break;
        case 'title-descending':
          params.sort = 'title-descending';
          break;
        case 'price-descending':
          params.sort = 'price-descending';
          break;
        case 'price-ascending':
          params.sort = 'price-ascending';
          break;
        default:
      }
    } else {
      params.sort = state.defaultSortBy;
    }

    // Create query string from params object
    Object.keys(params).forEach((key) => {
      url.searchParams.append(key, params[key]);
    });

    // Insert filters
    if (state.filterTagsApplied.length) {
      state.filterTagsApplied.forEach((filter) => {
        const filterKey = filter.split(':')[0];
        const filterVal = filter.split(`${filterKey}:`)[1];
        url.searchParams.append(`${filterKey}[]`, filterVal);
      });
    }

    try {
      const config = {
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios(config);
      const data = await res.data;
      let products = data.products;
      if (products.constructor === Object) {
        setProducts([], 0);
      } else {
        setProducts(products, data.total_product);
        setFilters(data.filter.options);
        if (state.totalProductsCount === 0) {
          search.error = `Sorry, nothing matched "${props.query}".`;
        }
      }
    } catch (err) {
      throw err
    }
    state.loading = false;
  };

  return {
    state,
    getProducts,
    setProducts,
    setFilters,
    getFilteredCollection,
    filterToggle,
    clearFilters,
  };
});
