<template>
  <transition name="fade">
    <div v-show="props.drawer_open" v-cloak class="collection__filter__drawer">
      <template v-if="!useCollection.state.filters.length">
        <div class="collection__filter__drawer__container">
          <div class="collection__filter__drawer__header">
            <div class="collection__filter__drawer__title">
              <h4>Filter products</h4>
              <div @click="$emit('toggle-drawer')">
                <icon-close />
              </div>
            </div>
          </div>
          <div class="collection__filter__drawer__no__results">
            <p class="accent">There are no filters to display</p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="collection__filter__drawer__container">
          <div class="collection__filter__drawer__header">
            <div class="collection__filter__drawer__title">
              <h4>Filter products</h4>
              <div @click="$emit('toggle-drawer')">
                <icon-close />
              </div>
            </div>
            <div
              v-if="useCollection.state.filtersApplied.length"
              @click="useCollection.clearFilters(props.collection_id)"
              class="collection__filter__drawer__reset"
            >
              <p class="accent">Reset filters</p>
            </div>
          </div>
          <div
            v-for="(filterGroup, index) in useCollection.state.filters"
            :key="index"
            class="collection__filter__category"
          >
            <div
              :class="[
                'collection__filter__toggle',
                { open: useCollection.state.filtersOpen.includes(index) },
              ]"
              @click="
                filterToggleBtn(index);
                useCollection.state.sortByOpen = false;
              "
            >
              <p class="collection__filter__title accent">
                {{ filterGroup.label }}
              </p>
              <icon-chevron
                :class="
                  useCollection.state.filtersOpen.includes(index)
                    ? 'up'
                    : 'down'
                "
              ></icon-chevron>
            </div>
            <transition-expand>
              <div
                class="collection__filter__items"
                v-show="useCollection.state.filtersOpen.includes(index)"
              >
                <ul>
                  <li
                    v-for="(option, index) in filterGroup.values"
                    :key="index"
                    @click="
                      useCollection.filterToggle(
                        option.key,
                        option.key,
                        props.collection_id,
                        filterGroup.filterOptionId,
                        $event
                      )
                    "
                    :class="[
                      'collection__filter__item',
                      showActive(option.key),
                    ]"
                  >
                    <p>{{ stripKey(option.key) }}</p>
                  </li>
                </ul>
              </div>
            </transition-expand>
          </div>
        </div>
      </template>
    </div>
  </transition>
</template>

<script setup>
import { reactive, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useCollectionStore } from '../../stores/collection';
import IconClose from './icons/IconClose.vue';
import TransitionExpand from './transitions/TransitionExpand.vue';
import IconChevron from './icons/IconChevron.vue';

const props = defineProps({
  drawer_open: {
    type: Boolean,
    required: true,
  },
  collection_handle: {
    type: String,
    required: false,
  },
  collection_id: {
    type: String,
    required: false,
  },
  page: {
    type: Number,
    required: false,
  },
  page_template: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['toggle-drawer']);

watch(
  () => props.page,
  (pageNum) => {
    useCollection.getFilteredCollection(pageNum, props.collection_id);
  }
);

const useCollection = useCollectionStore();
const { state, getProducts } = storeToRefs(useCollection);

const data = reactive({
  currentPage: 1,
  metafields: null,
});

const search = reactive({
  error: '',
});

const loadCollection = async (page) => {
  await useCollection.getFilteredCollection(page, props.collection_id);
  // Filters open by default
  for (const key in useCollection.state.filters) {
    useCollection.state.filtersOpen.push(Number(key));
  }
};

const filterToggleBtn = (idx) => {
  if (useCollection.state.filtersOpen.includes(idx)) {
    const index = useCollection.state.filtersOpen.indexOf(idx);
    useCollection.state.filtersOpen.splice(index, 1);
  } else {
    useCollection.state.filtersOpen.push(idx);
  }
};

const stripKey = (tag) => {
  if (/:(.+)/.exec(tag) && /:(.+)/.exec(tag).length) {
    return /:(.+)/.exec(tag)[1].trim();
  }
  return tag;
};

/* eslint-disable consistent-return */
const showActive = (option) => {
  if (useCollection.state.filtersApplied.includes(option))
    return 'collection__filter__item--active';
  if (useCollection.state.sortByFilter.includes(option))
    return 'collection__filter__item--active';
};

onMounted(() => {
  loadCollection(data.currentPage);
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}
</style>
