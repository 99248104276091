<template>
  <div class="collection__filters__sort__dropdown" v-show="props.sort_open">
    <p
      @click="
        useCollection.filterToggle(
          'sort: best-selling',
          'best-selling',
          props.collection_id
        )
      "
      :class="['collection__filters__sort__option', showActive('Best selling')]"
    >
      Best Selling
    </p>
    <p
      @click="
        useCollection.filterToggle(
          'sort: title-ascending',
          'title-ascending',
          props.collection_id
        )
      "
      :class="['collection__filters__sort__option', showActive('A - Z')]"
    >
      A - Z
    </p>
    <p
      @click="
        useCollection.filterToggle(
          'sort: title-descending',
          'title-descending',
          props.collection_id
        )
      "
      :class="['collection__filters__sort__option', showActive('Z - A')]"
    >
      Z - A
    </p>
    <p
      v-if="props.customer.logged_in"
      @click="
        useCollection.filterToggle(
          'sort: price-descending',
          'price-descending',
          props.collection_id
        )
      "
      :class="[
        'collection__filters__sort__option',
        showActive('Price High - Low'),
      ]"
    >
      Price, High - Low
    </p>
    <p
      v-if="props.customer.logged_in"
      @click="
        useCollection.filterToggle(
          'sort: price-ascending',
          'price-ascending',
          props.collection_id
        )
      "
      :class="[
        'collection__filters__sort__option',
        showActive('Price Low - High'),
      ]"
    >
      Price, Low - High
    </p>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useCollectionStore } from '../../stores/collection';

const props = defineProps({
  collection_id: {
    type: String,
    required: false,
  },
  sort_open: {
    type: Boolean,
    required: true,
  },
  customer: {
    type: Object,
    required: true,
  },
});

const useCollection = useCollectionStore();
const { state, getProducts } = storeToRefs(useCollection);

const showActive = (option) => {
  if (useCollection.state.filtersApplied.includes(option))
    return 'collection__active__filters__item--active';
  if (useCollection.state.sortByFilter.includes(option))
    return 'collection__active__filters__item--active';
};
</script>
