<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0665 18.2978H19.3024C19.6879 18.2978 20 18.0119 20 17.6596C20 17.307 19.6879 17.0211 19.3024 17.0211H11.0661C10.7621 16.0401 9.77516 15.3192 8.60455 15.3192C7.43593 15.3192 6.4473 16.039 6.14297 17.0211H0.697551C0.312114 17.0211 0 17.307 0 17.6596C0 18.0119 0.312114 18.2978 0.697551 18.2978H6.14297C6.44698 19.2787 7.43394 20 8.60455 20C9.77317 20 10.7621 19.2802 11.0665 18.2978V18.2978ZM18.0433 10.6383H19.3024C19.6879 10.6383 20 10.3528 20 10.0001C20 9.64752 19.6879 9.36198 19.3024 9.36198H18.043C17.739 8.381 16.752 7.65973 15.5814 7.65973C14.4128 7.65973 13.4241 8.37953 13.1198 9.36198H0.697717C0.31228 9.36198 0.000167291 9.64752 0.000167291 10.0001C0.000167291 10.3528 0.31228 10.6383 0.697717 10.6383H13.1198C13.4238 11.6193 14.4108 12.3406 15.5814 12.3406C16.75 12.3406 17.739 11.6208 18.0433 10.6383V10.6383ZM6.88036 2.97888H19.3024C19.6879 2.97888 20 2.69304 20 2.34041C20 1.98809 19.6879 1.70225 19.3024 1.70225H6.88036C6.57635 0.721264 5.58939 0 4.41878 0C3.25016 0 2.26119 0.719798 1.95687 1.70225H0.697717C0.31228 1.70225 0.000167291 1.98809 0.000167291 2.34041C0.000167291 2.69304 0.31228 2.97888 0.697717 2.97888H1.9572C2.26121 3.95986 3.24817 4.68082 4.41878 4.68082C5.5874 4.68082 6.57603 3.96102 6.88036 2.97888Z"
      fill="#303030"
    />
  </svg>
</template>
